.setUp {
  order: 1;
  flex-grow: 1;
  background: rgb(150, 180, 200);
  /* border: 1px solid rgb(78, 78, 78); */
  font-size: 16px;
  font-weight: bold;
  /* margin-right: -1px; */
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
}

select {
  margin-left: 10px;
}

button {
  margin-left: 10px;
}
