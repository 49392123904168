.mathSigns {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  font-size: 60px;
  padding: 0px;
  margin: 0px;
  text-align: center;
  width: 50px;
  height: 60px;
}

.mathSigns-x {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  font-size: 60px;
  padding: 0px;
  margin-top: -15px;
  text-align: center;
  width: 50px;
  height: 60px;
}