.nrSquare {
  background: rgb(150, 180, 200);
  /* border: 1px solid rgb(78, 78, 78); */
  font-family: "Futura";
  font-size: 70px;
  font-weight: bold;
  line-height: 52px;
  height: 60px;
  margin: 0px;
  padding: 0;
  text-align: center;
  width: 120px;
}
