.gameButtonsContainer {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: stretch;
  align-items: center;
  max-width: 200px;
  gap: 5px;
}

.gameButtons {
 
  margin: auto;
  font-size: 17px;
}
