.optionElement {
  display: flex;
  align-items: center;
  margin-top: 7px;
  width: 80px;
}

.optionBtn {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 15px;
  font-weight: bold;
  /* float: left; */
  width: 25px;
  height: 25px;
}

.optionBtnPressed {
  margin-right: 8px;
  font-size: 15px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  border: inset 2px rgba(80, 80, 80);
  box-shadow: inset 2px 2px rgb(80, 80, 80, 0.5);
}

.optionInput {
  width: 22px;
  height: 18px;
  text-align: right;
}
