p {
  margin: auto;
}

.stats {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(170, 200, 220);
  border: 1px solid rgb(78, 78, 78);
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
  height: 100px;
  max-width: 200px;;
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}
