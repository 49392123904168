body {
  font: 14px "Century Gothic", Futura, sans-serif;
  background-color: rgb(150, 180, 200);
  width: 100%;
  height: 100%;
}

.game {
  display: flex;
  margin: 30px;
}

.equation-game {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  border: 6px solid rgb(50, 50, 150);
  border-radius: 15px;
  padding: 20px;
  margin: auto;
  gap: 5px;
}

.gameContainer {
  order: 3;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

.counter-game {
  position: relative;
  border: 1px solid #999;
  padding: 20px;
  margin: 30px;
  width: 650px;
  height: 250px;
}

.optionButtonContainer {
  order: 2;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: space-around;
  align-items: flex-start;
  background: rgb(150, 180, 200);
  /* border: 1px solid rgb(78, 78, 78); */
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 5px;
}

.optionButtons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
}

.game-board {
  order: 1;
  margin: 10px;
  padding: 10px;
  flex-grow: 2;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

.game-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  order: 2;
  margin: 10px;
  padding: 10px;
  flex-grow: 1;
  gap: 10px;
}

.counter-game {
  position: relative;
  border: 1px solid #999;
  padding: 20px;
  margin: 30px;
  width: 650px;
  height: 250px;
}
/* 
.gameButtons {
  width: auto;
} */
