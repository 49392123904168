.equation {
  order: 2;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
  background: rgb(150, 180, 200);
  border: 2px solid rgb(50, 50, 150);
  border-radius: 5px;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}
